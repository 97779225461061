import React from 'react';

import { locationService } from '@grafana/runtime';
import { Alert } from '@grafana/ui';
import { getBackendSrv } from 'app/core/services/backend_srv';
import { ContextSrv } from 'app/core/services/context_srv';

interface QueryData {
  PanelName: string;
  ValueColumnName: string;
  Metadata: string;
  Value: string;
  UserId?: number;
}
export class FrivindCustomModifications {
  contextSrv = new ContextSrv();
  orgId: number;
  userId: number;

  constructor() {
    this.orgId = this.contextSrv.user.orgId;
    this.userId = this.contextSrv.user.id;
  }

  alert() {
    return (
      <Alert severity="info" title="Dashboard not saved">
        Dashboard must be saved before alerts can be added.
      </Alert>
    );
  }

  async wait(delay: number, refreshValue: number) {
    const start = new Date().getTime();
    let end = start;
    while (end < start + delay) {
      end = new Date().getTime();
    }

    locationService.partial({ 'var-refresher': refreshValue }, true);
  }

  post(data: QueryData, refreshDelay: number | false) {
    data.UserId = this.userId;

    getBackendSrv()
      .post(`api/orgs/${this.orgId}/modification`, data)
      .then(() => {
        this.alert();

        if (refreshDelay) {
          const params = locationService.getSearch();
          const refresher = params.get('var-refresher');
          const refreshValue = refresher ? (parseInt(refresher, 10) + 1) % 2 : 1;
          this.wait(refreshDelay, refreshValue);
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  }

  getAll() {
    getBackendSrv()
      .get(`api/orgs/${this.orgId}/modifications`)
      .then((res) => {
        this.alert();
        console.log(res);
      })
      .catch((err) => {
        console.warn(err);
      });
  }
}
