import React, { useState } from 'react';
import { Cell } from 'react-table';

import { TimeRange, DataFrame, SelectableValue } from '@grafana/data';

import { IconName } from '../../types/icon';
import { Button } from '../Button/Button';
import { Select } from '../Select/Select';

import { TableStyles } from './styles';
import { GrafanaTableColumn, TableFilterActionCallback } from './types';

export interface ClickableCell {
  isClickable: boolean;
  rowNo?: number;
  type?: 'string' | 'number' | 'button' | 'select';
  refreshDelay: number | false;
  tableToUpdate?: string;
  updateField?: string;
  options?: ButtonProps[] | SelectableValue[];
}

export interface ButtonProps {
  value: string;
  icon: IconName;
  tooltip: string;
}

export interface Props {
  cell: Cell;
  tableStyles: TableStyles;
  onCellFilterAdded?: TableFilterActionCallback;
  columnIndex: number;
  columnCount: number;
  timeRange?: TimeRange;
  userProps?: object;
  frame: DataFrame;
  clickableCell?: ClickableCell;
  onCellChanged?: (clickableCell: ClickableCell, valueColumnName: string, value: string | number | boolean) => void;
}

export const TableCell = ({
  cell,
  tableStyles,
  onCellFilterAdded,
  timeRange,
  userProps,
  frame,
  clickableCell,
  onCellChanged,
}: Props) => {
  const cellProps = cell.getCellProps();
  const field = (cell.column as unknown as GrafanaTableColumn).field;

  const [isInputVisible, setInputVisible] = useState(clickableCell?.type === 'button');
  const [inputValue, setInputValue] = useState(cell.value);

  if (!field?.display) {
    return null;
  }

  if (cellProps.style) {
    cellProps.style.minWidth = cellProps.style.width;
    cellProps.style.justifyContent = (cell.column as any).justifyContent;
  }

  let innerWidth = ((cell.column.width as number) ?? 24) - tableStyles.cellPadding * 2;

  const handleCellClick = () => {
    if (clickableCell && clickableCell.isClickable) {
      setInputVisible(true);
    }
  };

  const handleInputChange = (event: any) => {
    if (clickableCell?.type === 'button') {
      setInputValue(event.value);
      setInputVisible(false);
      valueChanged(event.value);
    } else {
      setInputValue(event.target.value);
    }
  };

  const handleSelectChange = (selection: SelectableValue | null) => {
    if (clickableCell?.type === 'select' && selection) {
      setInputValue(selection.value);
      valueChanged(selection.value, selection.label);
    }
  };

  const handleInputKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      setInputVisible(false);
      const value = clickableCell?.type === 'number' ? parseFloat(inputValue) : inputValue;
      valueChanged(value);
    }
  };

  const handleOnBlur = () => {
    setInputVisible(false);
  };

  const valueChanged = (value: string | number | boolean, displayValue?: string | null) => {
    if (onCellChanged && clickableCell) {
      onCellChanged(clickableCell, field.name, value);
      cell.value = displayValue ?? value;
    }
  };

  const cellRender = cell.render('Cell', {
    field,
    tableStyles,
    onCellFilterAdded,
    cellProps,
    innerWidth,
    timeRange,
    userProps,
    frame,
  });

  if (cellProps.style) {
    cellProps.style.height = '100%';
  }

  const buttonRowStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    gap: 2,
    marginLeft: 2,
  };

  const inputField = (
    <div style={cellProps.style}>
      {clickableCell?.type === 'button' ? (
        <div style={buttonRowStyle}>
          {clickableCell.options?.map((element, index) => {
            const e = element as ButtonProps;
            return (
              <Button
                key={index}
                size="md"
                icon={e.icon}
                tooltip={element.tooltip}
                onClick={() => {
                  valueChanged(e.value);
                }}
                variant="secondary"
              ></Button>
            );
          })}
        </div>
      ) : clickableCell?.type === 'select' ? (
        <Select
          options={clickableCell?.options}
          value={inputValue}
          onChange={handleSelectChange}
          onCloseMenu={handleOnBlur}
          openMenuOnFocus={true}
          autoFocus={true}
          style={{ textAlign: 'right', width: '100%', height: '100%' }}
        />
      ) : (
        <input
          type={clickableCell?.type}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyPress}
          onBlur={handleOnBlur}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
          style={{ textAlign: 'right', width: '100%', height: '100%' }}
        />
      )}
    </div>
  );

  return (
    <div role="presentation" onClick={handleCellClick}>
      {isInputVisible ? inputField : cellRender}
    </div>
  );
};
